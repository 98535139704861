import $ from "jquery";
import "../sideBar/classie";

var SidebarMenuEffects = (function () {
  function hasParentClass(e, classname) {
    if (e === document) return false;
    if (classie.has(e, classname)) {
      return true;
    }
    return e.parentNode && hasParentClass(e.parentNode, classname);
  }

  function init() {
    var container = document.getElementById("st-container"),
      reset = document.getElementById("closeMenu"),
      buttons = Array.prototype.slice.call(
        document.querySelectorAll("#st-trigger-effects > button")
      ),
      eventtype = "click",
      resetMenu = function () {
        classie.remove(container, "st-menu-open");
      },
      bodyClickFn = function (evt) {
        if (!hasParentClass(evt.target, "st-menu")) {
          resetMenu();
          document.removeEventListener(eventtype, bodyClickFn);
        }
      },
      resetClickFn = function (evt) {
        if (evt.target == reset) {
          resetMenu();
          document.removeEventListener(eventtype, bodyClickFn);
        }
      };

    buttons.forEach(function (el, i) {
      var effect = el.getAttribute("data-effect");

      el.addEventListener(eventtype, function (ev) {
        ev.stopPropagation();
        ev.preventDefault();
        container.className = "st-container"; // clear
        classie.add(container, effect);
        setTimeout(function () {
          classie.add(container, "st-menu-open");
        }, 25);
        document.addEventListener(eventtype, bodyClickFn);
        document.addEventListener(eventtype, resetClickFn);
      });
    });
  }

  init();
})();

// sidebar close button
$(".sidebar-close").on("click", function () {
  $(".st-container").removeClass("st-menu-open");
  $(".header").removeClass("header-hidden");
  $(".header").addClass("visible");
  $(".body").removeClass("overflow");
});

$(".sidebar-logo").on("click", function () {
  $(".st-container").removeClass("st-menu-open");
  $(".header").removeClass("header-hidden");
  $(".header").addClass("visible");
  $(".body").removeClass("overflow");
});

$(".menu-bar").click(function () {
  $(".header").removeClass("visible");
  $(".header").addClass("header-hidden");
  $(".body").addClass("overflow");

});

$(".st-pusher").click(function () {
  $(".header").addClass("visible");
});

$("#menu-3").click(function (e) {
  e.stopPropagation();
});
